import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import { SWRConfig } from 'swr'
import Snackbar from '@material-ui/core/Snackbar'

import ShowAppOrLogin from '../components/ShowAppOrLogin'
import constructIconLibrary from '../helpers/iconLibrary'
import { fetchProfileData } from '../ducks/profiles'
import { getLiveDevKey } from '../helpers/APIkeys'
import { DENDRO_API_URL } from '..'

export class App extends Component {
  constructor(props) {
    super(props)

    const token = Cookies.get('key')

    this.state = {
      userId: '',
      token: token,
      isUserLoggedIn: !!token,
      newVersionAvailable: false,
    }

    constructIconLibrary()
  }

  componentDidMount() {
    this.setAuthorizationDefaults()
    this.setAuthTokenFromCookie()
    this.props.fetchProfileData()

    const startTime = Date.now()

    const versionCheckWorker = new Worker('/js/versionCheckWorker.js')
    versionCheckWorker.postMessage({ action: 'start' })

    versionCheckWorker.onmessage = (e) => {
      if (e.data.action !== 'check') return

      axios.get('/api/latest_update_time/').then((response) => {
        if (!!response.data && new Date(response.data) > startTime) {
          this.setState({ newVersionAvailable: true })
          versionCheckWorker.postMessage({ action: 'stop' })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //Set global authorization headers for axios using new token
    const { token } = this.state
    if (token.length > 0 && token !== prevState.token) {
      this.setAuthorizationDefaults()
      this.testCredentials()
      this.props.fetchProfileData()
    }

    //Poll cookies to log out user on current tab if they
    //log out on another tab
    const isLoggedIn = this.state.isUserLoggedIn
    const wasLoggedIn = prevState.isUserLoggedIn

    if (!!isLoggedIn && !wasLoggedIn) {
      this.cookiePoll = window.setInterval(() => {
        const cookie = Cookies.get('key')
        if (!cookie && !!isLoggedIn) {
          this.logoutUser()
        }
      }, 5000)
    }

    if (!isLoggedIn && !!wasLoggedIn) {
      clearInterval(this.cookiePoll)
    }
  }

  logoutUser = () => {
    Cookies.remove('key')
    this.setState({ userId: '', token: '', isUserLoggedIn: false })
    this.clearAuthorizationDefaults()
  }

  testCredentials = () => {
    axios
      .get('/api/check_login/')
      .then((response) => {
        const { data } = response

        if (data && data.userId) {
          this.setState({
            userId: data.userId.toString(),
            isUserLoggedIn: true,
          })
        } else {
          this.logoutUser()
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.logoutUser()
        }
      })
  }

  storeAuthTokenAsCookie = (token) => {
    //Persist token in cookie
    Cookies.set('key', token, { expires: 7 })
    this.setAuthTokenFromCookie()
  }

  setAuthTokenFromCookie = () => {
    //Set token in local state
    const cookie = Cookies.get('key')
    if (!!cookie) {
      this.setState({ token: cookie })
    } else {
      this.logoutUser()
    }
  }

  setAuthorizationDefaults = () => {
    const { token } = this.state

    if (token && token.length > 0) {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`
      axios.defaults.withCredentials = true
    }
  }

  clearAuthorizationDefaults = () => {
    axios.defaults.headers.common['Authorization'] = null
    axios.defaults.withCredentials = false
  }

  newVersionSnackbarMessage = () => {
    return (
      <div className="text-center">
        There is a new version of Dendro available.{' '}
        <button
          className="my-1 w-full text-base text-blue-400 transition hover:underline"
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh now
        </button>
      </div>
    )
  }

  render() {
    return (
      <SWRConfig
        value={{
          fetcher: (url) => {
            if (process.env.NODE_ENV === 'production') {
              url = url.startsWith('/') ? url : `/${url}`
              url = DENDRO_API_URL + url
            }
            return axios.get(url).then((res) => res.data)
          },
        }}
      >
        <Snackbar
          open={this.state.newVersionAvailable}
          autoHideDuration={null}
          message={this.newVersionSnackbarMessage()}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />
        <ShowAppOrLogin
          loggedIn={this.state.isUserLoggedIn}
          storeAuthToken={this.storeAuthTokenAsCookie}
          logoutUser={this.logoutUser}
        />
      </SWRConfig>
    )
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  }
}

export default withRouter(connect(mapStateToProps, { fetchProfileData })(App))
